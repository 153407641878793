import { addErrorState } from './debug-state-actions';
import { normalizeError } from '../../services/monitoring';

export function handleError({
  store,
  actions,
  actionsPromisified,
  setProps,
  appParams,
  captureToSentry,
}) {
  return error => {
    const state = store && store.getState();
    console.error('--- error in createForumAppController ---', error);
    const shouldCaptureToSentry = captureToSentry !== undefined;
    if (shouldCaptureToSentry) {
      captureToSentry(normalizeError);
    }
    setProps({
      state,
      actions,
      actionsPromisified,
      cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
      workerError: error.toString(),
      wasWorkerErrorCaptured: shouldCaptureToSentry,
    });
    store && store.dispatch(addErrorState(error));
  };
}
