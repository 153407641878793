import createAction from '../../common/services/create-action';

export const FETCH_BADGES_REQUEST = 'badges/FETCH_REQUEST';
export const FETCH_BADGES_SUCCESS = 'badges/FETCH_SUCCESS';
export const FETCH_BADGES_FAILURE = 'badges/FETCH_FAILURE';

export const fetchBadgesRequest = createAction(FETCH_BADGES_REQUEST);
export const fetchBadgesSuccess = createAction(FETCH_BADGES_SUCCESS, payload => payload);
export const fetchBadgesFailure = createAction(FETCH_BADGES_FAILURE);

export const fetchBadges = () => {
  return (dispatch, getState, { badgesRequest, wixCodeApi }) => {
    dispatch(fetchBadgesRequest());
    const config = {
      headers: {
        Authorization: wixCodeApi.user.currentUser.instance,
        instance: null,
      },
    };
    const promise = badgesRequest('/v1/badges', config);

    return promise
      .then(
        res => dispatch(fetchBadgesSuccess(res.badges)),
        () => dispatch(fetchBadgesFailure()),
      )
      .then(() => promise);
  };
};
