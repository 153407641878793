import { ACTIVATE_SUBSCRIPTIONS } from '../components/messages/message-types';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { getApplicationConfig } from '../../common/selectors/communities-context-selectors';
import { PAID_PLANS_APP_ID } from '../constants/applications';
import { hasMembersGroupsByType } from '../selectors/members-groups-selectors';
import { GROUP_TYPE_PAID_PLAN } from '../constants/manage-category-members';
import { EXPERIMENT_PAID_PLANS } from '../constants/experiments';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { isOwner } from '../../common/store/current-user/current-user-selectors';
import { fetchSiteId } from './fetch-site-id';

export const showActivateSubscriptionMessage = () => dispatch =>
  dispatch(showMessage(ACTIVATE_SUBSCRIPTIONS));

export const showActivateSubscriptionMessageIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  const isPaidPlansInstalled = getApplicationConfig(state, PAID_PLANS_APP_ID).isInstalled;
  const hasPaidPlanGroups = hasMembersGroupsByType(state, GROUP_TYPE_PAID_PLAN);

  if (
    isExperimentEnabled(state, EXPERIMENT_PAID_PLANS) &&
    isOwner(state) &&
    !isPaidPlansInstalled &&
    hasPaidPlanGroups
  ) {
    dispatch(fetchSiteId()).then(() => dispatch(showActivateSubscriptionMessage()));
  }
};
