import { isArray, keyBy } from 'lodash';

export const getMembersGroups = state => state.membersGroups;
export const getMembersGroupsByIds = (state, idList) =>
  getMembersGroups(state).filter(g => idList.includes(g.id));
export const hasMembersGroupsByType = (state, type) =>
  getMembersGroups(state).findIndex(g => g.type === type) > -1;
export const getMembersGroupsByType = (state, type) => {
  const list = getMembersGroups(state).filter(group =>
    isArray(type) ? type.includes(group.type) : group.type === type,
  );
  const byId = keyBy(list, 'id');

  return {
    list,
    byId,
    count: list.length,
  };
};
