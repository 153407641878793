import { PAID_PLANS_APP_ID } from '../constants/applications';
import { CLOSE_MODAL } from '../../common/modals/framework/store/modal-actions';
import { getApplicationConfig } from '../../common/selectors/communities-context-selectors';
import { wasOfflineMigrationTriggered } from '../selectors/offline-migration-selectors';
import { reloadPage } from '../actions/reload-page';

export default function createRefreshAfterOnlineMigrationMiddleware() {
  return store => next => action => {
    switch (action.type) {
      case CLOSE_MODAL:
        const state = store.getState();
        if (!getApplicationConfig(state, PAID_PLANS_APP_ID).isInstalled) {
          if (action.payload.type === 'categories-manager' && wasOfflineMigrationTriggered(state)) {
            store.dispatch(reloadPage());
          }
        }

      default:
        next(action);
    }
  };
}
