import uri from 'urijs';
import createAction from 'redux-actions/lib/createAction';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { buildPaginationRequestParams, getLastPage } from '../services/pagination';
import { WHO_LIKED_PAGE_SIZE } from '../components/who-liked/who-like-constants';
import { getWhoLiked } from '../selectors/who-liked-selector';

export const FETCH_LIKES_DATA_REQUEST = 'FETCH_LIKES_DATA_REQUEST';
export const FETCH_LIKES_DATA_SUCCESS = 'FETCH_LIKES_DATA_SUCCESS';
export const FETCH_LIKES_DATA_FAIL = 'FETCH_LIKES_DATA_FAIL';

export const fetchLikesDataRequest = createAction(FETCH_LIKES_DATA_REQUEST);
export const fetchLikesDataSuccess = createAction(FETCH_LIKES_DATA_SUCCESS);
export const fetchLikesDataFail = createAction(FETCH_LIKES_DATA_FAIL);

export function fetchLikesData({ postId, commentId } = {}) {
  return (dispatch, getState, { request }) => {
    fetchLikesDataRequest({ postId, commentId });
    const fetchedLikesLastPage = getWhoLiked(getState(), postId || commentId).lastPage;
    const nextPage = fetchedLikesLastPage + 1;
    const params = buildPaginationRequestParams(nextPage, WHO_LIKED_PAGE_SIZE);
    const promise = request(
      uri(`/likes/${postId ? 'post' : 'comment'}/${postId || commentId}`).query(params),
    );
    return promise
      .then(data =>
        dispatch(fetchLikesDataSuccess({ postId, commentId, data: data.likes, page: nextPage })),
      )
      .catch(() => dispatch(fetchLikesDataFail({ postId, commentId })))
      .then(() => promise);
  };
}

export const fetchLikesDataPromisified = createPromisifiedAction(fetchLikesData, () => null);
